import { getRequest, postRequest } from "./request";

class HistoricoService {
  getHistoricoAutoComplete = () =>
    new Promise((resolve, reject) => {
      getRequest(`historico`, resolve, reject);
    });

  postHistoricoPesquisa = (objectFilter, objectTags, objectResult,indexGraphGeo) =>
    new Promise((resolve, reject) => {
      postRequest(
        `historico/register`,
        { objectFilter, objectTags, objectResult, indexGraphGeo },
        resolve,
        reject
      );
    });

  postHistoricoGeoGraph = (historico) => {
    new Promise((resolve, reject) => {
      postRequest(`historico/register`, historico, resolve, reject);
    });
  };

  ajustaParametros(p_objectFilters) {
    
    var filters = p_objectFilters.objectFilter;
    var objectFindHistory = {};

    if (filters !== undefined) {
      objectFindHistory.cnpj = filters.cnpj;
      objectFindHistory.razaoSocial = filters.razaoSocial;

      if (filters.capitalSocial !== undefined) {
        var capitalSocialSemDecimal = filters.capitalSocial.substring(
          0,
          filters.capitalSocial.length - 2
        );

        // var capitalSocialAux = formatNumberMongoDBToReal(capitalSocialSemDecimal);
        objectFindHistory.capitalSocial = capitalSocialSemDecimal;
      }

      objectFindHistory.porteEmpresa = filters.porteEmpresa;
      objectFindHistory.nomeSocio = filters.nomeSocio;
      objectFindHistory.raizCNPJ = filters.raizCNPJ;

      if (filters.listarEmpresasDaBase === 1) {
        objectFindHistory.listarEmpresasDaBase = true;
      }
      if (filters.listarEmpresasFiliais === 1) {
        objectFindHistory.listarFiliais = true;
      }

      if (filters.listarEmpresaNaoAtivas === 1) {
        objectFindHistory.listarEmpresasNaoAtivas = true;
      }

      var tagsFilters = p_objectFilters.objectTagsFilter;

      if (tagsFilters !== undefined) {
        objectFindHistory.tagsMunicipio = tagsFilters.tagsMunicipio;
        objectFindHistory.tagsCnaes = tagsFilters.tagsCnaes;
        objectFindHistory.result = tagsFilters.result;
        objectFindHistory.tagsEstado = tagsFilters.tagsEstado;
      }
      
    }

    return objectFindHistory;
  }
}

export default new HistoricoService();
