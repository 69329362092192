import { getWithParamsRequest, getRequest, putRequest } from "./request";
import {
  descricaoSituacaoCadastral,
  descricaoFilialMatrix,
  descricaoPorteEmpresa,
  UFPorExtenso,
} from "../utils/CodigoParaDescricao";
import { converteDataMongo } from "../utils/convertDataMongoDB";
import { formatNumberMongoDBToReal } from "../utils/formatNumberToReal";
import {
  montaLinkParaGoogleMaps,
  montaLinkParaGoogle,
  montaLinkParaLinkedin,
  montaLinkParaTwitter,
} from "../utils/montaLinksExternos";

class EmpresaService {
  getEmpresas = (params) =>
    new Promise((resolve, reject) => {
      getWithParamsRequest(`empresa/empresaFind`, { params }, resolve, reject);
    });

  getEmpresa = (cnpj) =>
    new Promise((resolve, reject) => {
      getRequest(`empresa/cnpj/${cnpj}`, resolve, reject);
    });

  ObjectParamFind(
    razaoSocial,
    capitalSocial,
    tagsMunicipio,
    tagsEstado,
    tagsCnaes,
    porteEmpresa,
    nomeSocio,
    listarEmpresasDaBase,
    desconsidEmpReservada,
    listarFiliais,
    listarEmpresaNaoAtivas,
    formadetributacao,
    anosFind,
    pageNumber,
    desconsidEmpContatadaUmAno
  ) {
    var params = {};
    console.log(desconsidEmpContatadaUmAno);
    if (razaoSocial !== undefined && razaoSocial !== "") {
      params.razaoSocial = razaoSocial;
    }

    if ((capitalSocial !== undefined) & (capitalSocial !== "")) {
      var capitalSocialComCasasDecimais = capitalSocial;
      params.capitalSocial = capitalSocialComCasasDecimais;
    }

    if (tagsMunicipio !== undefined && tagsMunicipio !== "") {
      params.arrayMunicipio = [];
      var i;
      for (i in tagsMunicipio) {
        var municipio = tagsMunicipio[i].id.toUpperCase();
        params.arrayMunicipio[i] = municipio
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      }
    }

    if (tagsEstado !== undefined && tagsEstado !== "") {
      params.arrayUF = [];
      i = 0;
      for (i in tagsEstado) {
        params.arrayUF[i] = tagsEstado[i].id;
      }
    }

    if (tagsCnaes !== undefined && tagsCnaes !== "") {
      params.arrayCnae = [];
      i = 0;
      for (i in tagsCnaes) {
        params.arrayCnae[i] = tagsCnaes[i].id;
      }
    }

    if (porteEmpresa !== undefined && porteEmpresa !== "") {
      params.porteEmpresa = porteEmpresa;
    }
    if (formadetributacao !== undefined && formadetributacao !== "") {
      params.formadetributacao = formadetributacao;
    }

    if (anosFind !== undefined && anosFind !== "") {
      params.anosFind = anosFind;
    }

    if (nomeSocio !== undefined && nomeSocio !== "") {
      params.socio = nomeSocio;
    }

    if (listarEmpresasDaBase !== undefined && listarEmpresasDaBase !== false) {
      params.listarEmpresasDaBase = 1;
    }

    if (
      desconsidEmpReservada !== undefined &&
      desconsidEmpReservada !== false
    ) {
      params.desconsidEmpReservada = desconsidEmpReservada == true ? 1 : 0;
    }
    if (
      desconsidEmpContatadaUmAno !== undefined &&
      desconsidEmpContatadaUmAno !== false
    ) {
      params.desconsidEmpContatadaRecente = desconsidEmpContatadaUmAno == true ? 1 : 0;
    }

    if (listarFiliais !== undefined && listarFiliais !== false) {
      params.listarEmpresasFiliais = 1;
    }

    if (pageNumber !== undefined) {
      params.pageNumber = pageNumber;
    }

    if (
      listarEmpresaNaoAtivas !== undefined &&
      listarEmpresaNaoAtivas !== false
    ) {
      params.listarEmpresaNaoAtivas = 1;
    }

    return params;
  }

  formataEmpresa = (data) => {
    if (data !== undefined) {
      var JsonData = data.data[0];

      var companyObject;

      companyObject = { ...companyObject };

      companyObject.totalDeFiliais = data.totalDeFiliais;
      companyObject.cnpj = JsonData["cnpj"];
      companyObject.identificadorMF = descricaoFilialMatrix(
        JsonData["identificadorMF"]
      );
      companyObject.razaoSocial = JsonData["razaoSocial"];
      companyObject.naturezaJuridica = JsonData["naturezaJuridica"];
      companyObject.descricaonaturezaJuridica =
        JsonData["descricaonaturezaJuridica"];
      companyObject.qualificacaoResponsavel =
        JsonData["qualificacaoResponsavel"];
      companyObject.descricaoqualificacaoResponsavel =
        JsonData["descricaoqualificacaoResponsavel"];
      companyObject.motivoScadastral = JsonData["motivoScadastral"];
      companyObject.descricaomotivoScadastral =
        JsonData["descricaomotivoScadastral"];
      companyObject.dataInicioAtividade = JsonData["dataInicioAtividade"];
      companyObject.situacaoEspecial = JsonData["situacaoEspecial"];
      companyObject.dataSituacaoEspecial = JsonData["dataSituacaoEspecial"];
      companyObject.fantasia = JsonData["fantasia"];
      companyObject.situacaoCadastral = descricaoSituacaoCadastral(
        JsonData["situacaoCadastral"]
      );
      companyObject.dataSituacaoCadastral = converteDataMongo(
        JsonData["dataSituacaoCadastral"]
      );

      companyObject.dataInicioAtividade = converteDataMongo(
        JsonData["dataInicioAtividade"]
      );
      companyObject.cnae = JsonData["cnaeFormat"];
      companyObject.capitalSocial = formatNumberMongoDBToReal(
        JsonData["capitalSocial"]
      );
      companyObject.porteEmpresa = descricaoPorteEmpresa(
        JsonData["porteEmpresa"]
      );

      companyObject.telefone1 =
        `(` + JsonData["dddTelefone1"] + `) ` + JsonData["telefone1"];
      companyObject.telefone2 =
        `(` + JsonData["dddTelefone2"] + `) ` + JsonData["telefone2"];
      companyObject.email = JsonData["email"];

      companyObject.endereco =
        JsonData["tipoLogradouro"] +
        " " +
        JsonData["logradouro"] +
        " " +
        JsonData["numeroLogradouro"];
      companyObject.maps = montaLinkParaGoogleMaps(
        JsonData["tipoLogradouro"],
        JsonData["logradouro"],
        JsonData["numeroLogradouro"],
        JsonData["municipio"]
      );

      companyObject.google = montaLinkParaGoogle(
        companyObject.razaoSocial.replace(/ /g, "+")
      );
      companyObject.linkedin = montaLinkParaLinkedin(
        companyObject.razaoSocial.replace(/ /g, "%20")
      );
      companyObject.twitter = montaLinkParaTwitter(
        companyObject.razaoSocial.replace(/ /g, "%20")
      );

      companyObject.bairro = JsonData["bairro"];
      companyObject.municipio = JsonData["municipio"];
      companyObject.cdPais = "1";
      companyObject.tipoLogradouro = JsonData["tipoLogradouro"];
      companyObject.logradouro = JsonData["logradouro"] + `,`;
      companyObject.numeroLogradouro = JsonData["numeroLogradouro"];
      companyObject.complementoLogradouro = JsonData["complementoLogradouro"];
      companyObject.cep = JsonData["cep"];
      companyObject.uf = UFPorExtenso(JsonData["uf"]);
      companyObject.codigoUF = JsonData["uf"];
      companyObject.cliente = JsonData["cliente"];

      companyObject.empresaCigam = JsonData["empresaCigam"];
      companyObject.cnaeSecundaria = JsonData.cnaeSecundarioFormat;

      if (companyObject.empresaCigam === undefined) {
        //   const resFindEmp = await cigamAPISQLServer.get('/codigoEmpresa/'+cnpjParam)
        //   if(resFindEmp !== undefined)
        //   {
        //       companyObject.empresaCigam = resFindEmp.data.cd_empresa
        //   }
      }

      // var i;
      if (JsonData.socios !== undefined) {
        var arraySocios = JsonData.socios;

        // for (i in JsonData.socios) {
        //   arraySocios[i] = JsonData.socios[i].nomeSocio;
        // }
        companyObject.socios = arraySocios;
      }

      if (JsonData.regimeTributario !== undefined) {
        var arrayTributario = JsonData.regimeTributario;
        companyObject.regimeTributario = arrayTributario;
      }
      //dados simples nacional
      if (JsonData.simples !== undefined && JsonData.simples !== null) {
        var arraySimples = JsonData.simples;
        companyObject.dadosSimples = arraySimples;
      }

      return companyObject;
    }
  };
}

export default new EmpresaService();
